@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


$md: 768px;
$ld: 1280px;
$shadow: 0 3px 6px rgba(144, 160, 196, 0.34);

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.app-layout {
  min-height: 100vh !important;
}

h1, h2 {
  margin: 0 !important;

}

.logo span {
  line-height: 15px !important;
  font-size: 15px !important;
  font-weight: bold;
}

.page-title {
  padding: .8rem 1rem;

}

.ant-layout-sider {
  background: white !important;
}

.ant-menu {
  height: 100%;
}

.header {
  background: white !important;
  box-shadow: $shadow;
  z-index: 1;
  padding: 0 1rem 0 1.5rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading-cards {
  display: grid;
  margin: 0 1rem 1rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: $ld) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.one-column {
  grid-template-columns:  1fr;
  @media (max-width: $ld) {
    grid-template-columns:  1fr;
  }
}

a {
  font-weight: 600;
  color: black !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.loading-card {
  background: white;
  width: 100%;
  padding: 1rem;
  gap: .5rem;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;

  label {
    font-weight: 800;
  }

  span {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: 1.18rem;
  }
}

tbody, .logo {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace !important;
}

.table-block {
  margin: 1rem;
}

.success {
  font-weight: 800;
  color: darkgreen;
}

.failed {
  font-weight: 800;
  color: darkred;
}

.search {
  height: 40px;
  display: flex !important;
  align-items: center !important;
  width: 300px !important;
}

.message-block {
  margin: 0 1rem;
  font-size: 1rem;
}
.letter {
  height: 30px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5em;
}

.ParticleBackground {
  position: absolute;
  top: 0;
  height: 64px !important;
  z-index: 0;
}
